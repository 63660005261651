import { Box, Typography, Stack } from "@mui/material";

// import { graph } from "../../../../service/graph-req";
// import Masonry from "../Trending/Masonry";
// import getBestPaperImage from "../../../../service/getBestPaperImage";
// import { CardProductSize2 } from "../../../../component/Card/Product";
import FilledCard from "../../../../component/Card/Filled";

export default function ConferenceCard() {
  //
  return (
    <FilledCard
      raised
      tone="secondary.container"
      href="/neurips"
      sx={() => ({
        cursor: "pointer",
        mt: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexWrap: { expanded: "wrap", large: "nowrap" }
      })}
    >
      <Box
        maxHeight={128}
        component="img"
        width="100%"
        src="https://upload.wikimedia.org/wikipedia/en/0/08/Logo_for_Conference_on_Neural_Information_Processing_Systems.svg"
      />
      <Stack justifyContent="center" alignItems="center" width="100%">
        <Typography
          align="center"
          color="secondary.on.container"
          sx={{ textWrap: "balance" }}
          typography={{ compact: "headlineMd", expanded: "displaySm" }}
          fontWeight={{ compact: 600, expanded: 700 }}
        >
          Bytez + NeurIPS 2024
        </Typography>
        <Typography
          paragraph
          align="center"
          typography={{ compact: "titleMd", expanded: "titleLg" }}
          color="secondary.on.container"
        >
          Click to access paper summaries
        </Typography>
      </Stack>
    </FilledCard>
  );
}

// async function getPapers() {
//   const papers = await graph("conferenceTop6", {
//     id: ["30105", "31220", "29618", "31643", "29599", "29812"]
//   });
//   const imagesAssigned = new Set();
//   const promises = [];

//   for (const paper of papers) {
//     paper.scoreSota = 1;
//     promises.push(getBestPaperImage(paper, imagesAssigned));
//   }

//   await Promise.all(promises);

//   return papers;
// }
