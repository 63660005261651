"use client";
import { ButtonBase } from "@mui/material";

import ToolTip from "../Tooltip";
import { _Icon } from "./index";

const ButtonOutlined = ({
  dark,
  selected,
  IconStart,
  IconEnd,
  tooltip,
  label = "",
  fullWidth,
  size = "medium",
  disabled = false,
  sx = {},
  ...props
}) => (
  <ToolTip title={tooltip} sx={fullWidth ? { width: "100%" } : undefined}>
    <ButtonBase
      disabled={disabled}
      sx={theme => ({
        px: 3,
        border: 1,
        width: fullWidth ? "100%" : undefined,
        borderColor: disabled
          ? theme.palette.alpha(theme.palette.surface.on.color, 0.12)
          : "outline.color",
        borderRadius: theme.shape.round,
        height: size === "small" ? 32 : 40,
        transition: theme.transitions.create("all"),
        typography: {
          compact: "labelMd",
          expanded: `label${size === "small" ? "Md" : "Lg"}`
        },
        color: disabled
          ? theme.palette.alpha(theme.palette.surface.on.color, 0.38)
          : dark
          ? "dark.primary.color"
          : "primary.color",
        "&:hover": {
          backgroundImage: `linear-gradient(${theme.palette.alpha(
            theme.palette.secondary.on.container,
            0.08
          )}, ${theme.palette.alpha(
            theme.palette.secondary.on.container,
            0.08
          )})`
        },
        ...sx
      })}
      {...props}
    >
      {IconStart ? <_Icon Icon={IconStart} disabled={disabled} /> : null}
      {label}
      {IconEnd ? <_Icon end Icon={IconEnd} disabled={disabled} /> : null}
    </ButtonBase>
  </ToolTip>
);

export default ButtonOutlined;
