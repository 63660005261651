"use client";
import { useEffect, useRef, useState } from "react";
import {
  Box,
  Collapse,
  Stack,
  Divider,
  Typography,
  Skeleton
  // IconButton
} from "@mui/material";
import {
  // Close as CloseIcon,
  Settings as TuneIcon,
  Search as SearchIcon,
  ExpandLess as ShowLessIcon,
  ExpandMore as ShowMoreIcon
} from "@mui/icons-material";
import { Masonry } from "masonic";
import useSWR from "swr";

import { useStore, observer } from "../../../../service/mobx";
import { fetcher } from "../../../../service/graph";
import { PaperCardSize3 } from "../../../../component/Card/Paper";
import { CardProductSize2 } from "../../../../component/Card/Product";
import { ModelCardSize3 } from "../../../../component/Card/Model";
import { CardExternalCodeSize2 } from "../../../../component/Card/External";
import Distill from "./Distill";
import ChipFilter from "../../../../component/Chip/Filter";
import ButtonOutlined from "../../../../component/Button/Outlined";
import HorizontalChips from "../../../../component/Chip/Horizontal";
import useSignInDialog from "../../../../component/Dialog/dialogs/appWide/Login";
import ButtonText from "../../../../component/Button/Text";
import Popup from "../../../../component/Popup";
import { PaperDistillation } from "./Distill";
import MenuTask from "../../../../component/Menu/menus/Search/Task";
import Conference from "../Conference";

function Following() {
  const [following, setFollowing] = useState([]);
  const [task, setTask] = useState("all");
  const { device, stars, user } = useStore();
  const sections = [
    { sort: "trending", entity: "papers" },
    { sort: "trending", entity: "models" },
    { sort: "new", entity: "papers" },
    { sort: "new", entity: "models" },
    { sort: "popular", entity: "papers" },
    { sort: "popular", entity: "models" }
  ];

  useEffect(() => {
    if (user.loaded) {
      const notPhone = device.isPhone === false;

      setFollowing([
        { id: "all", name: "all" },
        ...(user.isAnonymous
          ? [
              notPhone
                ? { id: "text-generation", name: "text generation" }
                : undefined,
              { id: "chat", name: "chat" },
              { id: "text-to-image", name: "image generation" },
              notPhone
                ? { id: "object-detection", name: "object detection" }
                : undefined
            ].filter(defined => defined)
          : stars.tasks)
      ]);

      return () => setFollowing([]);
    }
  }, [user, user.loaded, device.isPhone, stars.tasks]);

  return (
    <>
      <Box position="sticky" top={0} zIndex={3} py={1} bgcolor="surface.color">
        <HorizontalChips
          chips={[
            ...following.map(_task => (
              <ChipFilter
                key={_task.id}
                label={_task.name}
                small={device.isPhone}
                selected={task === _task.name}
                onClick={() => {
                  setTask(_task.name);
                  window.scrollTo(0, 0);
                }}
              />
            )),
            user.loaded ? <TuneYourFeed key="tune" /> : null
          ]}
        />
      </Box>
      {/* {user.loaded && user.isAnonymous && user.firstExperience ? (
        <Banner />
      ) : null} */}
      <Conference />
      {sections.map(({ sort, entity }) => (
        <Section
          key={sort + entity + task}
          task={task}
          sort={sort}
          entity={entity}
        />
      ))}
    </>
  );
}

export default observer(Following);

const Section = observer(function Section({ task, sort, entity }) {
  const [showMore, setShowMore] = useState(false);
  const { data, isLoading } = useSWR(
    `/api/following/${task}/${entity}/${sort}`,
    fetcher
  );
  const { device } = useStore();
  const ref = useRef();
  const label = `${sort} ${entity}`;
  const columns = {
    compact: 1,
    medium: 2,
    expanded: 1,
    large: 2,
    extraLarge: entity === "papers" && sort !== "trending" ? 3 : 2
  };
  const columnCount = columns[device.size];
  const previewSize = device.isPhone ? 3 : 6;

  return isLoading ? (
    <Stack direction="row" spacing={1}>
      {new Array(3).fill().map((_, index) => (
        <Skeleton
          key={index}
          animation="wave"
          width={320}
          height={200}
          sx={{
            position: "relative",
            borderRadius: theme => theme.shape.md.round
          }}
        />
      ))}
    </Stack>
  ) : data?.length ? (
    <Box key={device.isPhone}>
      <Stack
        pt={7}
        ref={ref}
        direction="row"
        alignItems="baseline"
        justifyContent="space-between"
      >
        <Typography
          paragraph
          variant="titleLg"
          color="surface.on.color"
          textTransform="capitalize"
          fontWeight={{ compact: 600, expanded: 700 }}
        >
          {label}
        </Typography>
        {entity === "papers" && data.length === 12 ? (
          <ButtonText
            size="small"
            label={`search ${
              device.size === "expanded" || device.size === "compact"
                ? ""
                : label
            }`}
            IconStart={SearchIcon}
            href={`/search?type=papers&sort=${sort}${
              task === "all" ? "" : `&task=${task}`
            }`}
            sx={{ color: "secondary.color", textWrap: "balance" }}
          />
        ) : null}
      </Stack>
      <Masonry
        overscanBy={Infinity}
        rowGutter={8}
        columnGutter={8}
        maxColumnCount={3}
        render={render}
        columnCount={columnCount}
        items={data.slice(0, previewSize)}
      />
      <Collapse mountOnEnter unmountOnExit in={showMore}>
        <Masonry
          overscanBy={Infinity}
          rowGutter={8}
          columnGutter={8}
          render={render}
          maxColumnCount={3}
          columnCount={columnCount}
          items={data.slice(previewSize, 100)}
        />
      </Collapse>
      {previewSize < data.length ? (
        <Stack direction="row" alignItems="center" pt={3}>
          <Divider sx={{ flexGrow: 1, borderColor: "rgba(0,0,0,.1)" }} />
          <ButtonOutlined
            label={`Show ${showMore ? "less" : "more"}`}
            IconEnd={showMore ? ShowLessIcon : ShowMoreIcon}
            onClick={() =>
              setShowMore(showMore => {
                const showingMore = !showMore;

                if (showingMore) {
                  setTimeout(() => {
                    ref.current.scrollIntoView({
                      block: "start",
                      behavior: "smooth"
                    });
                  }, 1e3);
                }

                return showingMore;
              })
            }
            sx={{ borderColor: "rgba(0,0,0,.1)", color: "secondary.color" }}
          />
          <Divider sx={{ flexGrow: 1, borderColor: "rgba(0,0,0,.1)" }} />
        </Stack>
      ) : null}
    </Box>
  ) : null;
});

const TuneYourFeed = observer(function TuneYourFeed() {
  const { device, menu, user } = useStore();
  const signUp = useSignInDialog("Tune your feed");

  return (
    <ChipFilter
      StartIcon={TuneIcon}
      small={device.isPhone}
      label="Tune your feed"
      onClick={
        user.isAnonymous
          ? signUp
          : event =>
              menu.configure({
                anchor: event.target,
                Component: TaskFollow,
                sx: {
                  paper: { maxHeight: "70vh !important" },
                  menuList: { pt: 0, maxHeight: "100%" }
                }
              })
      }
      sx={{
        bgcolor: "primary.color",
        "& p, svg": { color: "primary.on.color" }
      }}
    />
  );
});

const TaskFollow = observer(function TaskFollow() {
  const { stars, snackbar, tasks } = useStore();
  const tasksFollowed = stars.tasks.map(task => task.name);

  return (
    <MenuTask
      key={stars.tasks.length}
      value={tasksFollowed}
      onChange={(_, values) => {
        const set = new Set(tasksFollowed);
        const newState = new Set(values.map(([task]) => task));

        // stop following
        for (const task of set) {
          if (newState.has(task) === false) {
            const entity = tasks.map.get(task);

            stars.quickSave({ save: false, entity });
            snackbar.notify({
              line1: "Stopped following:",
              line2: task,
              actions: [
                {
                  label: "Undo",
                  onClick() {
                    snackbar.set.open(false);
                    stars.quickSave({ save: true, entity });
                  }
                }
              ]
            });
          }
        }

        // follow
        for (const task of newState) {
          if (set.has(task) === false) {
            const entity = tasks.map.get(task);

            stars.quickSave({ save: true, entity });
            snackbar.notify({
              line1: "Following:",
              line2: task,
              actions: [
                {
                  label: "Undo",
                  onClick() {
                    snackbar.set.open(false);
                    stars.quickSave({ save: false, entity });
                  }
                }
              ]
            });
          }
        }
      }}
    />
  );
});

const render = ({ data }) => (
  <>
    {data.id === "distill" ? (
      <Distill papers={data.papers} />
    ) : data.__typename === "paper" ? (
      <Popup Component={() => <PaperDistillation paper={data} />}>
        <CardProductSize2 tags paper={data} />
      </Popup>
    ) : data.models.length ? (
      <ModelCardSize3 tags model={data.models[0]} />
    ) : data.label === "trending papers" ? (
      <Popup Component={() => <PaperDistillation paper={data} />}>
        <PaperCardSize3 paper={data} />
      </Popup>
    ) : (
      <CardExternalCodeSize2 content={{ node: data }} />
    )}
  </>
);

// function Banner() {
//   const [show, setShow] = useState();

//   useEffect(() => {
//     setShow(sessionStorage.getItem("feed-banner") === null);
//   }, []);

//   return (
//     <Collapse mountOnEnter unmountOnExit in={show === true}>
//       <Stack
//         mt={2}
//         p={1}
//         pl={2}
//         useFlexGap
//         spacing={2}
//         direction="row"
//         bgcolor="primary.color"
//         alignItems="center"
//         borderRadius={theme => theme.shape.md.round}
//       >
//         <TuneIcon sx={{ color: "primary.on.color" }} />
//         <Typography variant="bodyLg" color="primary.on.color">
//           Here's a starter feed. Tune your feed by following tasks and AI
//           communities
//         </Typography>
//         <IconButton
//           sx={{ ml: "auto" }}
//           onClick={() => {
//             setShow(false);
//             sessionStorage.setItem("feed-banner", 1);
//           }}
//         >
//           <CloseIcon sx={{ color: "primary.on.color" }} />
//         </IconButton>
//       </Stack>
//     </Collapse>
//   );
// }
